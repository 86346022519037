// store/modules/auth.js
const state = {
  user: '',
  access: '',
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setAccess(state, access) {
    state.access = access;
  },
};

const actions = {
  login({ commit }, credentials) {
    // Ваша логика аутентификации пользователя
    const { username, password } = credentials;

    if (username === 'admin' && password === 't<Rc#,') {
      commit('setUser', 'admin');
      commit('setAccess', 'admin');
    } else if (username === 'vogroup' && password === 'Starten12#') {
      commit('setUser', 'master');
      commit('setAccess', 'master');
    } else if (username === 'viewer' && password === 'YiGFr9') {
      commit('setUser', 'viewer');
      commit('setAccess', 'viewer');
    } else {
      commit('setUser', null);
      commit('setAccess', null);
    }

    // Возвращаем результат аутентификации
    return { success: !!state.user, access: state.access };
  },
  logout({ commit }) {
    commit('setUser', null);
    commit('setAccess', null);
  },
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  isAdmin: (state) => state.access === 'admin',
  isMaster: (state) => state.access === 'master',
  isViewer: (state) => state.access === 'viewer',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
